import { containerClasses } from '@mui/system';
import { convertIfPresent } from 'firebase-functions/lib/common/encoding.js';
import React, {useState} from 'react'
import {storage, firestore, Timestamp} from '../config/Config.js'
// import { setUserProperties } from '@firebase/analytics';

//components

export class AddReview extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            CustomerName: '',
            ReviewText: '',
            Email: '',
            // Date: 0, 
            ReviewImg: '', 
            // TotalScore: 0,  
            // AverageScore: 0, 
            Reviews: []      
        };          
        this.GetReviews = this.GetReviews.bind(this);   
        this.AddReview = this.AddReview.bind(this);   
        this.GeneratePromoCode = this.GeneratePromoCode.bind(this);
        this.handleReviewImg = this.handleReviewImg.bind(this);  
        this.handleAddReview = this.handleAddReview.bind(this); 
    }

    async GetReviews() {
        this.setState({
            // CustomerName: '',
            // ReviewText: '',
            // Email: '',
            // Date: 0, 
            // ReviewImg: '', 
            // TotalScore: 0,  
            // AverageScore: 0, 
            Reviews: []}
        );
                
        // var count = 0;

        const query = firestore.collection('Reviews')
                        .orderBy('Date')
                        .get()
                        .then((querySnapshot) => {
                            if (querySnapshot.empty) {
                                console.log('No Reviews yet...')
                            }
                          querySnapshot.forEach((doc) => {
                            this.setState({Reviews: [...this.state.Reviews, doc.data()]}); 
                            }                           
                          )
                        })


        // for (var doc of Reviews.docs){
        //     this.setState({Reviews: [...this.state.Reviews, doc.data()]}); //ARRAY DOUBLES HERE?!?
        //     // this.setState({TotalScore: this.state.TotalScore+doc.data().CustomerApproval})
        // }
        // // this.setState({AverageScore: this.state.TotalScore/Reviews.length})

    }

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            await this.GetReviews();              
        }
    }

    async componentDidMount() {       

        ///get the cart
        // await this.GetReviews(); 
    }

    

    handleReviewImg(e) {
        // Ensure that valid PNG or JPG files are selected for upload.
    const types = ['image/png', 'image/jpeg']
    let selectedFile = e.target.files[0];
    if (selectedFile && types.includes(selectedFile.type)){
        this.setState({ReviewImg: selectedFile});
        this.setState({Error: ''});
    }
    else
    {
        this.setState({ReviewImg: null});
        this.setState({Error: 'Please select a valid JPG or PNG file.'});
    }
    }


    async handleAddReview(e) {
        e.preventDefault();

        //validate email
        if ((this.state.Email.indexOf('@') == -1) || (this.state.Email.indexOf('.') == -1)) {
            alert('Please enter a valid email address.')
        }
        else {

            // this.AddReview().then(
            //     () => this.GeneratePromoCode().then(
            //             () => window.location.reload()
            //         )
            //     )
            this.AddReview()
        }

        
        
    }
    
    async AddReview() {
        // console.log(this.state.ReviewImg.name)
        const uploadTask = storage.ref(`review-images/${this.state.ReviewImg.name}`).put(this.state.ReviewImg); //upload image function
        
        // submit the customer review picture
        uploadTask.on('state_changed', 
            
            //on next
            snapshot=>{
            const progress = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            console.log(progress);
            }, //track upload progress

            //on error
            Error=>{this.setState(Error, Error.message)}, //report errors

            //on complete
            () => storage.ref('review-images').child(this.state.ReviewImg.name).getDownloadURL().then(async URL=>{
                await firestore.collection('Reviews').add({ //create firestore doc  
                    Date: Date.now(),
                    CustomerName: this.state.CustomerName,
                    ReviewImg: URL,
                    // CustomerApproval: this.state.CustomerApproval,
                    ReviewText: this.state.ReviewText
                }).then(() => {               
                this.GeneratePromoCode();  
                } );             
                }).catch(Error=>this.setState({Error: Error.messaage})) //catch any errors from image upload or firestore upload,
            )
    };  

    
    async GeneratePromoCode() {

        if (this.props.user) {
            if (!this.props.user.isAnonymous || this.props.user.email != 'soccerexpresionztt@gmail.com')
            var Email = this.props.user.Email
        }
        else{
            var Email = this.state.Email
        }

        const query = await firestore.collection('PromoCodes')
                        .where('Email', '==', Email)
                        .get()
                        .then(async (querySnapshot) => {
                            if (querySnapshot.empty) {
                                //there is no matching email that has already received a promocode for review. Go ahead and give one.

                                let letters = "0123456789ABCDEF";
                                var code = ''
                                
                                // Generating 3 digit hexcode
                                for (let i = 0; i < 3; i++)
                                    code += letters[(Math.floor(Math.random() * 16))];

                                

                                //create firestore doc
                                await firestore.collection('PromoCodes').doc('ZREV50$'+code).set({   
                                    Code: 'ZREV50$'+code,
                                    Description: "50$ OFF",
                                    Price: 50,
                                    Quantity: 1,
                                    Email: Email,
                                })  

                                // console.log('Promocode: '+'ZREV50$'+code)

                                firestore.collection('mail').add({
                                    to: Email,
                                    cc: 'soccerexpressionztt@gmail.com',
                                    template: {
                                        name: 'promocode',
                                        data: {
                                            promocode: 'ZREV50$'+code
                                            }
                                        }
                                    }).then(() => {
                                    console.log('Queued email for delivery!'); 
                                    if(!alert('As a thank you for your review a 50$ PromoCode ZREV50$'+code +' has been sent to '+Email+'. Thank you for your feedback!')){window.location.reload()}                                    
                                    }
                                    )

                            }
                            else {
                                //if a matching email is found then this user has already received a promocode for a review
                                if(!alert('Thank you for your review! You have already received one promocode for a previous review.')){window.location.reload()}                     
                          }
                        })

        
    
    }


        

    


    render() {

        var Reviews = [...this.state.Reviews] //copy this array so that we can reverse it later
        // console.log(Reviews.length)

        return (     
            <>
            <br/>
            <br/>
            <div className='container'>
                
                <form onSubmit={this.handleAddReview}> {/**/} 
                
                    <label>Name: &nbsp;</label>
                    <input
                        placeholder='Your Name...'
                        onChange={(e) => {this.setState({CustomerName: e.target.value})}}
                        value={this.state.CustomerName}
                        />

                    <br/>
                    <br/>

                    {(!this.props.user || (this.props.user && (this.props.user.isAnonymous || this.props.user.email == 'soccerexpressionztt@gmail.com'))) &&
                    <>      
                        <label>Email: &nbsp;</label>                                
                        <input 
                        placeholder='email@domain.com...'
                        required
                        class="" type='text' /* required={this.props.user.isAnonymous} */ onChange={(e) => this.setState({Email: e.target.value})} value={this.state.Email}/>
                    </>             
                    }
                    <br/>
                    <br/>
                    <label>Review: &nbsp;</label>
                    <br/>
                    <textarea
                        placeholder='Type your review here...'
                        onChange={(e) => {this.setState({ReviewText: e.target.value})}}
                        required
                        value={this.state.ReviewText}
                        rows={5}
                        cols={50}
                        />

                    <br/>
                    <br/>

                    <label>Image: &nbsp;</label>
                    
                    <input type="file"
                    id='ReviewImgfile'
                    required //{!this.state.ReUse} //{!(document.getElementById('ReUseImageCheckbox').checked)}
                    onChange={this.handleReviewImg}/>
                    <br/>
                    <br/>
                    <button>Submit Review</button>
                </form>
                {Error && <span>{Error}</span>}
            </div>
            <br/>
            <br/>
            <div className="w3-row">
                <h2>Customer Reviews</h2>
                <table className='center' width='50%'>
                        {Reviews.reverse().map((Review) => (
                                    <tr>
                                        <td>
                                            <div className="">
                                                <div style={{textAlign: "left"}}>
                                                <b>{Review.CustomerName}</b> &nbsp;
                                                <img height="16" width="16" alt="" src="https://static.xx.fbcdn.net/rsrc.php/v3/y6/r/2CYZBtJFeT2.png"></img>
                                                <br/>
                                                <small>{new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(Review.Date)}</small>
                                                <br/> 
                                                {Review.ReviewText}
                                                </div>
                                                <div className="w3-display-container">
                                                    <img src={Review.ReviewImg} width='100%' height="undefined" className="w3-display-container"/>
                                                </div>  
                                                <br/>
                                                <br/>
                                            </div>
                                        </td>                                            
                                    </tr>
                                     
                                
                            ))  } 
                </table>

            </div>
            </>   
        )
    }
}

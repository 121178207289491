import logo from './images/logo.png';
import banner from './images/banner.png'
import './App.css';
import './index.css';
import './css/w3-custom.css';
import { useState,  } from 'react' //useEffect
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom'
import {auth} from './config/Config.js' //firestore, storage, app, analytics

//firebase config
import firebase from 'firebase/compat/app';
import { useAuthState } from 'react-firebase-hooks/auth'
import 'firebase/compat/auth'

//components
import CustomizeJersey  from './components/CustomizeJersey.js' //Curly brackets not necessary bevase this is a wrapped component.
import { AddJersey } from './components/AddJersey.js'
import { AddReview } from './components/AddReview.js'
import { Promos } from './components/Promos';
import { AddPlayers } from './components/AddPlayers.js'
import { SignIn } from './components/SignIn.js'
import { DataDeletion } from './components/DataDeletion.js'
import { Cart } from './components/Cart.js'
import PayPal from './components/PayPal.js'
import { SearchStock } from './components/SearchStock.js'
import { Register } from './components/Register.js'
import { Reset } from './components/Reset.js'
import { CustomOrder } from './components/CustomOrder.js'
import { UpdateSalePrice } from './components/UpdateSalePrice.js'
import { RemoveEmptyStock } from './components/RemoveEmptyStock.js'

//icons
import { IoCartOutline } from "react-icons/io5"
// import { CgUser } from "react-icons/cg"
import { RiUserSharedFill, RiUserLine } from "react-icons/ri"
import { FaFacebookSquare, FaInstagramSquare} from "react-icons/fa"
import { TrackingEmail } from './components/TrackingEmail';
import { MdOutlineStarRate } from 'react-icons/md'

function App() {
  const [user] = useAuthState(auth);
  SignInUser = SignInUser.bind(this);


  return (
    <div className="App">
      {/* //Header Notice */}
      {/* <label className='notice'>End Of Season Clearance Sale!!! Buy one, GET ONE FREE!!!</label> */}
      <div className='header'>

        <img className='banner' src={banner}/>

        <div className='bannercontainer'>

          <div className='headerLogo'>
            <a href='/' ><img className='logo' src={logo}/></a>
          </div>

          <div className='headerIcons'>
            {/* <BrowserRouter>   
              <Switch>         */}
                {/* <a href='/Reviews'><MdOutlineStarRate size={32}/></a> */}
                {user ? 
                  /* <Link to='/' className="Sign-In-btn" onClick={() => {if (user!==null) {auth.signOut()}}}><RiUserSharedLine size={32}/></Link> : */
                  !user.isAnonymous ?
                    <a href='/SignIn' title='Sign Out'><RiUserSharedFill size={32}/></a>
                    :
                    <a href='/SignIn' title='Sign In'><RiUserLine size={32}/></a>
                  :
                  <a href='/SignIn' title='Sign In'><RiUserLine size={32}/></a>
                }
                  {/* <Link to='/' className="Sign-In-btn" onClick={() => {if (user!==null) {auth.signOut()}}}>{user ? <RiUserSharedLine size={32}/> : <RiUserLine size={32}/>}</Link> */}
                  {/* {props.user ? <Route exact path='/' render={(props) => <CustomizeJersey {...props} uid={user.uid}/>}/>: <Route exact path='/' component={SignIn}/>} */}
                  <a href='/Cart' className="Cart-btn"><IoCartOutline size={32}/></a>
                  {/* <a href='/cart'><IoCartOutline/></a> */}
              {/* </Switch> 
            </BrowserRouter> */}
          </div>

        </div>

        

      </div>

      

      {/* <SignOut /> */}
      <section>
        <BrowserRouter>
          <Switch>
            
           
          {/* {user ? <Route exact path='/' render={(props) => <CustomizeJersey {...props} uid={user.uid}/>}/>: <Route exact path='/signin' component={SignIn}/>} */}          
          <Route exact path='/' render={(props) => <CustomizeJersey {...props} user={user} AnonSignIn={SignInUser}/>}/>
          <Route path='/DataDeletion' component={DataDeletion}/>

          <Route path='/AddJersey' component={AddJersey}/>
          <Route path='/RemoveEmptyStock' component={RemoveEmptyStock}/>
          <Route path='/UpdateSalePrice' component={UpdateSalePrice}/>
          <Route path='/Reviews' component={AddReview}/>
          <Route path='/Promos' component={Promos}/>
          <Route path='/AddPlayers' component={AddPlayers}/>
          <Route exact path="/reset" component={Reset} />
          <Route path='/Register' component={Register}/>
          {/* {user ? <Route path='/Cart' render={(props) => <Cart {...props} user={user}/>}/>: <Route exact path='/signin' component={SignIn}/>} */}
          <Route path='/Cart' render={(props) => <Cart {...props} user={user}/>}/>
          
          <Route path='/SignIn' render={(props) => <SignIn {...props} user={user}/>}/>
          <Route path='/Tracking' render={(props) => <TrackingEmail {...props} user={user}/>}/>
          <Route path='/Search' render={(props) => <SearchStock {...props} user={user} AnonSignIn={SignInUser}/>}/>
          <Route path='/CustomOrder' render={(props) => <CustomOrder {...props} user={user} AnonSignIn={SignInUser}/>}/>
          </Switch>
        </BrowserRouter>
      </section> 

      <br/> 
      <br/> 
      <div className='footer'>  
        <a href='/Reviews'>See what our valued customers think in their reviews! </a>
        <br/>
        <br/>
        Don't see the jersey or size you're looking for?<br/> 
        Contact us at <a href='http://www.facebook.com/soccerexpressionz'><FaFacebookSquare/> Facebook</a> or <a href='http://www.instagram.com/soccerexpressionz'><FaInstagramSquare/> Instagram</a> for a customized order.<br/>
        <br/>
        <a href='https://www.termsfeed.com/live/00c6b083-aa1c-453d-9efe-0bd27f824410'>Privacy Policy</a>        
        &nbsp; | &nbsp;
        <a href='/DataDeletion'>Data Deletion Policy</a>
        &nbsp; | &nbsp;
        <a href='https://www.termsfeed.com/live/2da60c43-2f3b-4cb3-8a7b-d8d676450ca9'>Return and Refund Policy</a>
      </div>     
      
    </div>
  );
}


// function SignInUser() {
//   const provider = new firebase.auth.GoogleAuthProvider();
//   return (auth.signInWithPopup(provider))
// }

// function SignInUser(provider) {
//   firebase.auth().signInWithPopup(provider).then((res) => {
//     return res.user;
//   }).catch((err) => {
//     return err;  
//   })
// }

// function SignIn() {

//   const signInWithGoogle = () => {
//     const provider = new firebase.auth.GoogleAuthProvider();
//     auth.signInWithPopup(provider)
//   }


//   return (
//     <>
//       <button className="sign-in" onClick={signInWithGoogle}>Sign in with Google</button>
//       {/* <p>Do not violate the community guidelines or you will be banned for life!</p> */}
//     </>
//   )

// }

function SignInUser() {
  return (
    // <div>
      auth.signInAnonymously()
  //  </div>
  )
}

// function SignOut() {
//   return auth.currentUser && (
//     <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
//   )
// }

export default App;
import React from 'react'
import PayPal from './PayPal.js'
import { useState } from 'react'
import {firestore, Timestamp} from '../config/Config.js'
import sendOrderConfirmationEmail from './Mailer.js'
import {BrowserRouter, Switch, Route, Link} from 'react-router-dom'
import {CartItem} from './CartItem.js'

// import base64url from "base64url"; 
// import {dotenv} from "dotenv"

//icons
import { MdRemoveShoppingCart, MdTungsten } from "react-icons/md"
import { RiEmotionSadLine } from "react-icons/ri"
import {RiDeleteBin5Line} from 'react-icons/ri'
import { Alert } from 'bootstrap'
import { FaPaypal, FaCcVisa, FaCcMastercard } from 'react-icons/fa'

export class Cart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Email: '',
            PickupChecked: false,
            CustomizeChecked: false,
            checkout: false,
            Items:[],
            TotalPrice:0,
            ShippingPrice:0,
            DeliveryPrice:50,
            DeliveryName: '',
            DeliveryAddress1: '',
            DeliveryAddress2: '',
            DeliveryCity: '',
            DeliveryTelNumber: '',
            DeliveryTelNumberConfirm: '',
            EstimatedDeliveryMin: {},
            EstimatedDeliveryMax: {},
            COD: false,
            PromoCodeApplied: false,
            PromoCode: '',
            DiscountDescription: '',
            DiscountPrice: 0,
            DiscountQuantity: 1,
            processing:false,}; 
        this.GetCart = this.GetCart.bind(this);
        this.UpdateStock = this.UpdateStock.bind(this);
        this.UpdateOrders = this.UpdateOrders.bind(this);
        this.EmptyCart = this.EmptyCart.bind(this);
        this.handleOrderApprove = this.handleOrderApprove.bind(this);
        //FOR TEMPORARY USE ONLY. REMOVE BEFORE BUILD ///////////////////////////////////////////////////////////
        this.handleTestOrderApprove = this.handleTestOrderApprove.bind(this);        
        //FOR TEMPORARY USE ONLY. REMOVE BEFORE BUILD ///////////////////////////////////////////////////////////
        this.handleQuantityChange = this.handleQuantityChange.bind(this);  
        this.handlePlayerNoteChange = this.handlePlayerNoteChange.bind(this);       
        this.CheckForShippingPrice = this.CheckForShippingPrice.bind(this);
        this.handleDeliveryCheckBoxChange = this.handleDeliveryCheckBoxChange.bind(this); 
        this.CheckPromoCode = this.CheckPromoCode.bind(this);
        this.CustomizeState = this.CustomizeState.bind(this);
        this.handleReceivePayment = this.handleReceivePayment.bind(this);
        this.SetCustomerData = this.SetCustomerData.bind(this);
        this.ValidateDelivery = this.ValidateDelivery.bind(this);
        this.BOGOF = this.BOGOF.bind(this);
        // this.ValidatePromoCode = this.ValidatePromoCode.bind(this);
    }


    async GetCart() {
        this.setState({Items: [], TotalPrice:0});
        if (this.props.user !== null) {

            const query = await firestore.collection('Users').doc(this.props.user.uid).collection('Cart')
                        .orderBy('Price')
                        .get()
                        .then((querySnapshot) => {
                            if (querySnapshot.empty) {
                                console.log('No Items in Cart yet...')
                            }
                          querySnapshot.forEach((doc) => {
                            this.setState({Items: [...this.state.Items, doc.data()]}); //ARRAY DOUBLES HERE?!?
                            this.setState({TotalPrice: this.state.TotalPrice+doc.data().Price})
                            }                           
                          )
                        })

            // const Cart = await firestore.collection('Users').doc(this.props.user.uid).collection('Cart').get();
            // for (var doc of Cart.docs){
            //     this.setState({Items: [...this.state.Items, doc.data()]}); //ARRAY DOUBLES HERE?!?
            //     this.setState({TotalPrice: this.state.TotalPrice+doc.data().Price})
            // }
            
            // this.BOGOHO()
            // this.BOGOF()

            //check for any custom items in the cart
            this.CheckForCustomization();
			await this.CheckForShippingPrice();
            // this.CheckInStock;
        }

    }

    //////////////////////////////////////////TEMPORARY BUY ONE GET ONE HALF OFF BOGOHO SALE////////////////////////////////
    
    BOGOHO() {       
        var CartLength = 0
        var BOGOPrice = 200 //MINIMUM PRICE OF JERSEY

        for (var item of this.state.Items) {  
            if (item.Price >= BOGOPrice) {
                CartLength++
            } 
        }
           
        if (CartLength >= 2) {
            this.setState({PromoCodeApplied: true, DiscountDescription: 'BOGOHO', DiscountQuantity: Math.floor(CartLength/2), DiscountPrice: -(Math.floor(CartLength/2)*(BOGOPrice/2)), TotalPrice: this.state.TotalPrice-(Math.floor(CartLength/2)*(BOGOPrice/2))})              
        } 
        else
        {
            this.setState({PromoCodeApplied: false, DiscountDescription: '', DiscountQuantity: 1, DiscountPrice: 0})     
        }    
    }
    //////////////////////////////////////////TEMPORARY////////////////////////////////

    //////////////////////////////////////////TEMPORARY BUY ONE GET FREE BOGOF SALE////////////////////////////////
    
    BOGOF() {                     
        var discount = 0

        // this.state.Items.sort((a, b) => a.price - b.price)

        for (let i = 0; i < Math.floor(this.state.Items.length/2); i++){
            discount = discount + this.state.Items[i].Price
        }           
           
        if (discount > 0) {
            this.setState({PromoCodeApplied: true, DiscountDescription: 'BOGOF', DiscountQuantity: 1, DiscountPrice: -(discount), TotalPrice: this.state.TotalPrice-(discount)})              
        } 
        else
        {
            this.setState({PromoCodeApplied: false, DiscountDescription: '', DiscountQuantity: 0, DiscountPrice: 0})     
        }    
    }
    //////////////////////////////////////////TEMPORARY////////////////////////////////

    async componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            ///get the cart and check for previously saved user data to set the customer data fields
            if (this.props.user)
            {
                await this.GetCart();  
                await this.CustomizeState(); 
            }           
        }
    }

    async componentDidMount() {       

        ///get the cart
        if (this.props.user)
        {
            await this.GetCart();  
        }
    }

    async UpdateStock() {
        //THIS IS HAPPENING TO FAST WHEN MULTIPLE ITEMS ARE ORDERED
        
        for (var item of this.state.Items){
            // const data = item.data();
            // const data = item;
            await firestore.collection('Leagues').doc(item.League).collection('Teams').doc(item.Team).collection('Cuts').doc(item.Cut).collection('Sleeves').doc(item.Sleeve).collection('Variants').doc(item.Variant).collection('Sizes').doc(item.Size).get().then( 
                async snapshot => {
                    if (snapshot.data() != undefined) {
                        const doc = snapshot.data();
                        var QuantityInStock = doc.StockQuantity;
                        // console.log('Original stock '+QuantityInStock);
                        if (QuantityInStock > 0){
                            QuantityInStock = QuantityInStock - item.Quantity;
                            // console.log('Updated stock '+QuantityInStock);                        
                            await firestore.collection('Leagues').doc(item.League).collection('Teams').doc(item.Team).collection('Cuts').doc(item.Cut).collection('Sleeves').doc(item.Sleeve).collection('Variants').doc(item.Variant).collection('Sizes').doc(item.Size).update({'StockQuantity': QuantityInStock});
                        }
                    }
                })
            
        }   
        if (this.state.PromoCodeApplied)  {
            await firestore.collection('PromoCodes').doc(this.state.PromoCode).get().then( 
                async snapshot => {
                    const doc = snapshot.data();
                    var Quantity = doc.Quantity;
                    Quantity = Quantity - 1;
                    await firestore.collection('PromoCodes').doc(this.state.PromoCode).update({'Quantity': Quantity});
                })    
        }
    }
    
    async UpdateOrders() {
        const time = Timestamp.now().valueOf() ;
        const Deliver= !this.state.PickupChecked;
        const DeliveryName= this.state.DeliveryName;
        const DeliveryAddress1= this.state.DeliveryAddress1;
        var DeliveryAddress2= this.state.DeliveryAddress2;
        const DeliveryCity= this.state.DeliveryCity;
        const DeliveryTelNumber= this.state.DeliveryTelNumber;
        const TotalPrice = this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice;
        const ShippingPrice = this.state.ShippingPrice;
        const DeliveryPrice = this.state.DeliveryPrice;
        const PromoCodeDesc = this.state.DiscountDescription;        
        const PromoCode = this.state.PromoCode;

        var ItemsArray= [];
        var ShippingMsg= '';

        if (DeliveryAddress2=='') {
            DeliveryAddress2='-'
        }

        
        //make sure the user email has been set correctly. we set it here again as there seems to be some conflict with the initialization of State
        if (this.props.user) {
            if ((this.props.user.email) && (this.props.user.email != 'soccerexpressionztt@gmail.com')) {
                this.setState({Email: this.props.user.email})                
                // console.log('email: ',this.props.user.email,',',this.state.Email)
            }
        }

        //console.log (Deliver, DeliveryName, DeliveryAddress1, DeliveryAddress2, DeliveryCity, DeliveryTelNumber);

        //retrieve the cart
        const Cart = await firestore.collection('Users').doc(this.props.user.uid).collection('Cart').get()


        //create a new order document with the TimeStamp as the order number
        await firestore.collection('Users').doc(this.props.user.uid).collection('Orders').doc(time).set({ 
            OrderNumber: time,
            PromoCode: PromoCode}).then( () => { 
                //if pickup checkbox is unchecked               
                if (Deliver) {
                    firestore.collection('Users').doc(this.props.user.uid).collection('Orders').doc(time).set({
                        //set any delivery details
                        Deliver: Deliver,
                        DeliveryName: DeliveryName,
                        DeliveryAddress1: DeliveryAddress1,
                        DeliveryAddress2: DeliveryAddress2,
                        DeliveryCity: DeliveryCity,
                        DeliveryTelNumber: DeliveryTelNumber
                    });
                    //add a tacking document for later use
                    firestore.collection('tracking').doc(time).set({
                        //set any delivery details
                        ordernumber: time,
                        DeliveryName: DeliveryName,
                        DeliveryTelNumber: DeliveryTelNumber,
                        trackingURL: '',
                        Email: this.state.Email
                    });
                    //push a delivery item to the order list
                    ItemsArray.push({text: 'Delivery',
                        image: '',
                        price: '$'+DeliveryPrice,
                        quantity: ''
                    });
                }                
            })

            //for every item in the cart
            for (var doc of Cart.docs){
                var PlayerNote = doc.data().PlayerNote
                var PrintOptions = doc.data().PrintOptions
                //if the note and print option details were left blank, set them to an empty string instead of undefined
                if (PlayerNote == undefined) {
                    PlayerNote = ''
                }
                if (PrintOptions == undefined) {
                    PrintOptions = ''
                }

                //then push the item to the order list
                ItemsArray.push({text: doc.data().Year+' '+doc.data().League+' '+doc.data().Team+' '+doc.data().Cut+' '+doc.data().Sleeve+' '+doc.data().Variant+' '+doc.data().Size+' '+doc.data().PlayerName+' '+doc.data().PlayerNumber,
                            image: doc.data().JerseyImgFront,
                            price: '$'+doc.data().Price,
                            quantity: doc.data().Quantity,
                            PlayerNote: PlayerNote,
                            PrintOptions: PrintOptions,
                        })
            }
            
            //if any promocodes were used, push it to the order list
            if (PromoCodeDesc != '') {
                ItemsArray.push({text: PromoCodeDesc})     
            }            

        //if shipping was selected, push it to the order list and set the shipping message for the email
        if (ShippingPrice !== 0){
            ItemsArray.push({text: 'Ordering and Shipping',
                            image: '',
                            price: '$'+ShippingPrice,
                            quantity: ''
                        });
            ShippingMsg = 'Your order contains items that are not in stock and will have to be ordered. SoccerExpressionz cannot guarantee shipping times due to congested global shipping lanes and customs delays. Please allow up to 3-5 weeks for delivery.'
        }  

        //copy the itemsarray to the customers orders collection
        firestore.collection('Users').doc(this.props.user.uid).collection('Orders').doc(time).collection('Items').doc(doc.data().League+doc.data().Team+doc.data().Cut+doc.data().Sleeve+doc.data().Variant+doc.data().Size+doc.data().PlayerName+doc.data().PlayerNumber).set({...doc.data()});                     
            
        
        
        //SEND EMAILS
        //queue the mail to mail collection
        firestore.collection('mail').add({
            to: this.state.Email,
            cc: 'soccerexpressionztt@gmail.com',
            template: {
                name: 'receipt',
                data: {
                    ordernumber: time,
                    total: TotalPrice,
                    items: ItemsArray,
                    receipt: !this.state.COD,
                    COD: this.state.COD,
                    deliver: Deliver,
                    name: DeliveryName,
                    address01: DeliveryAddress1,
                    address02: DeliveryAddress2,
                    city: DeliveryCity,
                    contactnumber: DeliveryTelNumber,
                    ShippingMsg: ShippingMsg,
                    }
                }
            }).then(() => console.log('Queued email for delivery!'));                
    }

    async handleRemoveFromCart(Jersey){  
        var PlayerName = '';
        var PlayerNumber = '';
        if (Jersey.PlayerNumber !== undefined){
            PlayerNumber = Jersey.PlayerNumber
        }
        
        if (Jersey.PlayerName !== undefined){
            PlayerName = Jersey.PlayerName;    
        }
        // console.log(this.props.user.uid)
        // console.log(Jersey.League+Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+PlayerName+PlayerNumber)
        await firestore.collection('Users').doc(this.props.user.uid).collection('Cart').doc(Jersey.League+Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+PlayerName+PlayerNumber).delete().then(async () => {
            // this.setState({Items: this.state.Items.filter(function(Jerseys) { 
            //     return Jerseys !== Jersey})});
            // this.setState({TotalPrice: this.state.TotalPrice-(Jersey.Price*Jersey.Quantity)});
            // await this.CheckForShippingPrice();
            // this.CheckForCustomization();
            // console.log('Deleted ',Jersey.League+Jersey.Team+Jersey.Cut+Jersey.Sleeve+Jersey.Variant+Jersey.Size+PlayerName+PlayerNumber,'from Cart ', this.props.user.uid );
            await this.GetCart();
            //remove any applied promocodes. The user must re-apply them so that the total can be recalculated.
            this.setState({PromoCodeApplied: false, DiscountQuantity: 0, DiscountDescription: '', DiscountPrice: 0})
            })

        
    }

    async EmptyCart() {
        if (this.props.user !== null) {
            await firestore.collection('Users').doc(this.props.user.uid).collection('Cart').get()
            .then(querySnapshot => {
                //delete all items in the cart, before deleting the cart itself
                querySnapshot.docs.forEach(snapshot => {
                    snapshot.ref.delete();
                })
            })
            .then(
                this.setState({checkout: false, Items:[], TotalPrice:0, ShippingPrice:0, DeliveryPrice:0, COD: false})
            )
        }
    }

    async handleOrderApprove(){
        await this.UpdateOrders();        
        await this.UpdateStock();
        await this.EmptyCart();        
        alert('Order confirmed. Thank you for your purchase! Your receipt has been sent to: '+this.state.Email);
        this.setState({checkout: false, COD: false, Items:[], TotalPrice:0, ShippingPrice:0, DeliveryPrice:0, DeliveryName: '', DeliveryAddress1: '', DeliveryAddress2: '', DeliveryCity: '', DeliveryTelNumber: '', processing:false,});
    }

    async handleTestOrderApprove(){
        await this.UpdateOrders();        
        // await this.UpdateStock();
        await this.EmptyCart();        
        alert('Order confirmed. Thank you for your purchase! Your receipt has been sent to: '+this.state.Email);
        this.setState({checkout: false, COD: false, Items:[], TotalPrice:0, ShippingPrice:0, DeliveryPrice:0, DeliveryName: '', DeliveryAddress1: '', DeliveryAddress2: '', DeliveryCity: '', DeliveryTelNumber: '', processing:false});
    }

    async handleQuantityChange(newquantity, item){ //newquantity, item

        //update Jersey quantity in Items array in memory
        var i = this.state.Items.indexOf(item);
        var ItemsCopy = this.state.Items;
        ItemsCopy[i].Quantity =  newquantity;
        this.setState({Items: ItemsCopy});

        //ensure that player name and number are not undefined
        var PlayerName = item.PlayerName
        var PlayerNumber = item.PlayerNumber
        if (PlayerName == undefined) {
            PlayerName = ''
        }
        if (PlayerNumber == undefined) {
            PlayerNumber = ''
        }

        //update Jersey quantity in firestore so it can be saved in case browser is closed        
        firestore.collection('Users').doc(this.props.user.uid).collection('Cart').doc(item.League+item.Team+item.Cut+item.Sleeve+item.Variant+item.Size+PlayerName+PlayerNumber).update({Quantity: newquantity})

        //recaluclate the total price
        var NewTotalPrice=0;
        for (item of this.state.Items) {
            NewTotalPrice = (item.Price*item.Quantity) + NewTotalPrice;
        }
        this.setState({TotalPrice: NewTotalPrice});

        //recalculate the shipping price
        await this.CheckForShippingPrice();
    }

    async handlePlayerNoteChange(newnote, item){ //newquantity, item

        //update Jersey quantity in Items array in memory
        var i = this.state.Items.indexOf(item);
        var ItemsCopy = this.state.Items;
        ItemsCopy[i].PlayerNote =  newnote;
        this.setState({Items: ItemsCopy});
        // console.log(newnote)

        //ensure that player name and number are not undefined
        var PlayerName = item.PlayerName
        var PlayerNumber = item.PlayerNumber
        if (PlayerName == undefined) {
            PlayerName = ''
        }
        if (PlayerNumber == undefined) {
            PlayerNumber = ''
        }

        //update Jersey quantity in firestore so it can be saved in case browser is closed        
        firestore.collection('Users').doc(this.props.user.uid).collection('Cart').doc(item.League+item.Team+item.Cut+item.Sleeve+item.Variant+item.Size+PlayerName+PlayerNumber).update({PlayerNote: newnote})
    }

    async CheckForShippingPrice() {
        this.setState({ShippingPrice: 0});           
            for (const item of this.state.Items) {
                // console.log(item.Size.split(' ')[0])
                if (item.Quantity > 0) {                
                    const doc = await firestore.collection('Leagues').doc(item.League).collection('Teams').doc(item.Team).collection('Cuts').doc(item.Cut).collection('Sleeves').doc(item.Sleeve).collection('Variants').doc(item.Variant).collection('Sizes').doc(item.Size.split(' ')[0]).get(); //use item.Size.split(' ')[0] to account for possible Youth Sizes which may be in format "2XL (28)"
                    if (doc.data() == undefined) {
                        this.setState({ShippingPrice: 70});
                        // console.log(this.state.ShippingPrice);
                        // return false;
                        break;
                    }
                    else{
                        if (doc.data().StockQuantity === 0){                
                            this.setState({ShippingPrice: 70});
                            // console.log(this.state.ShippingPrice);
                            break;
                        }
                    }
                }
            
            }
    }

    CheckForCustomization() { 
        var min = 0
        var max = 0

        for (const item of this.state.Items) {
            // console.log(item.PlayerName,item.PlayerNumber)
            var PlayerName = item.PlayerName
            var PlayerNumber = item.PlayerNumber

            if( PlayerName == undefined){
                PlayerName = ''}
            if( PlayerNumber == undefined){
                PlayerNumber = ''}

            //if the player name or number has been changed from blank values then items are custom
            if ((PlayerName != '') || (PlayerNumber != '')) {                
                this.setState({CustomizeChecked: true}); 
                // console.log('Custom items found')
                min = 7
                max = 10
                // console.log('long delivery')
                break;
            }
            else {
                this.setState({CustomizeChecked: false}); 
                // console.log('No Custom items found') 
                min = 3
                max = 6
                // console.log('short delivery')                   
            }  
        }
        //calculate the date by which items ordered today will be received
        let now = new Date();
        now.setDate(now.getDate()+min);
        this.setState({EstimatedDeliveryMin: Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "long",
            day: "2-digit"
          }).format(now)});
        now.setDate(now.getDate()+max);
        this.setState({EstimatedDeliveryMax: Intl.DateTimeFormat("en-GB", {
            year: "numeric",
            month: "long",
            day: "2-digit"
          }).format(now)});
    }

    handleDeliveryCheckBoxChange() {
        //if a Free Delivery coupon has been applied then we can no longer opt out of delivery. The cart will have to be refreshed to change this.
        //This is in order to prevent people getting a shipping price discount, and then opting out, which decreases the price even further, essentially giving a double discount.
        if (this.state.DiscountDescription != 'Free Delivery') {
            this.setState({PickupChecked: !this.state.PickupChecked})
            if (this.state.DeliveryPrice !== 0) {
                this.setState({DeliveryPrice: 0})
            }
            else {
                this.setState({DeliveryPrice: 50});
            }   
        }     
      }

    async ValidateDelivery() {
        // var result = true;
        console.log('Validating USer Data...')
        if (this.state.DeliveryName=='') {
            alert('Please enter a full name for your Order')
            return false;
            // console.log('Invalid Name');                
        }  
        if (this.props.user.isAnonymous || this.props.user.email == 'soccerexpressionztt@gmail.com') {
            if (this.state.Email=='') {
                alert('Please enter a email for your receipt')
                return false;
                // console.log('Invalid Email');  
            }              
        }
        if ((this.state.DeliveryTelNumber !='') || (this.state.DeliveryTelNumber != undefined)) {
            // console.log(this.state.DeliveryTelNumber)
            if ((this.state.DeliveryTelNumber.length != 7) || (this.state.DeliveryTelNumber.slice(0,3) == '868') || (this.state.DeliveryTelNumber.slice(0,4) == '1868')) {
                alert('Your contact number is incomplete. Please enter 7 digits.')
                return false;
            }
        }
        else
        {
            alert('Your contact number is incomplete. Please enter 7 digits.')
            return false;
        }
        if ((this.state.DeliveryTelNumber != this.state.DeliveryTelNumberConfirm)) {
            alert('Contact Numbers do not match.')
            return false;
        }

        if (this.state.PickupChecked == false) {
            if (this.state.DeliveryAddress1=='') {
                alert('Please enter an address for delivery')
                return false;
                // console.log('Invalid address');
            }
            else {
                if (this.state.DeliveryCity=='') {
                    alert('Please enter a city for delivery')
                    return false;
                    // console.log('Invalid city');
                }
                else {
                    // if (this.state.DeliveryTelNumber=='') {
                    //     alert('Please enter a contact number for delivery')
                    //     return false;            
                    //     // console.log('Invalid number');
                    // }
                    
                }                    
            }  
        }
        console.log('Valid Customer Data')
        return true;
    }

    async handleReceivePayment() {

        function CreateJWT(amount) {
            const jwt = require('jsonwebtoken');            
            const secret = process.env.REACT_APP_API_SECRET;
            const payload = {"amount": amount, "currency": "TTD"}

            var token = jwt.sign(payload, secret, { algorithm: 'HS256', header: {"typ": "JWT", "kid": process.env.REACT_APP_API_KEY} })

            // console.log(token);
            return token;
        }


        this.ValidateDelivery().then(
          //ensure that the enterred data is valid before taking customer to payment link
          (result) => {
            if (result) {
                alert('IMPORTANT! Click on the RETURN button after your transaction is complete to receive an order confirmation!')
                //set the customer data, this may have changed from what was previously enterred. If this is brand new info it will be needed for when the payment button returns us to this page for order confirmation
                this.SetCustomerData().then(
                    () => window.location.href = 'https://www.fygaro.com/en/pb/f8b6be5a-aae5-40e9-bb58-71fb84d5bc9c/?jwt='+CreateJWT(this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice)
                )
            }  
          }
          )      
      }

    async SetCustomerData() {
        // console.log(this.props.user.uid)
        if (this.state.DeliveryAddress2 == '') {
            this.setState({DeliveryAddress2: '-'})
        }
        await firestore.collection('Users').doc(this.props.user.uid).update({
          email: this.state.Email,
          name: this.state.DeliveryName,                
          deliverytelnumber: this.state.DeliveryTelNumber,
          deliverytelnumber: this.state.DeliveryTelNumberConfirm,
          address1: this.state.DeliveryAddress1,
          address2: this.state.DeliveryAddress2,
          city: this.state.DeliveryCity,
        })
      }

    async CustomizeState() {
        //THIS MAY BE BAD PRACTICE. WE ARE SETTING STATE FROM THE URL.
        ///THEREFORE WE HAVE A DUPLICATE INSTEAD OF SINGLE SOURCE OF TRUTH
    
        //try to load previously saved user data
        await firestore.collection('Users').doc(this.props.user.uid).get().then( 
          snapshot => {
              const user = snapshot.data();
            //   console.log(user)  
              this.setState({Email: user.email,
                DeliveryName: user.name,                
                DeliveryTelNumber: user.deliverytelnumber,
                DeliveryTelNumberConfirm: user.deliverytelnumber,
                DeliveryAddress1: user.address1,
                DeliveryAddress2: user.address2,
                DeliveryCity: user.city})
        } 
        )
    
        //if we came from the Fygaro payment button, check for the return URL and process the approved payment
        if (this.props.location.search != '') {
          const params = new URLSearchParams(this.props.location.search);
    
          if (params.get('jwt')) {           
            console.log('Return URL from Fygaro payment button. Validating JWT.') 
            if (this.state.Items.length > 0) { //check that there were items in the cart to approve

                function ValidateJWT(token) {
                    const jwt = require('jsonwebtoken');            
                    const secret = process.env.REACT_APP_API_SECRET;

                    try {
                        const payload = jwt.verify(token, secret)
                        // console.log(payload)
                        return payload;
                    }
                    catch (err) {
                        console.log(err)
                        alert ('An authentication error occured: ' + err.message)
                    }                    
                }

                
                const payload = ValidateJWT(params.get('jwt'))
                // console.log(payload.amount)
                
                if (payload != undefined) { //if the payload is verified succesfully we can assume that the JWT came from fygaro with the correct secret key. So we can go ahead and process the order                    
                    this.handleOrderApprove()
                }
                else
                { 
                    alert('An error occured in processing your payment.')
                }                
            
            }
          } 
        }
      }

    ValidatePromoCode (doc) {
        //check for code quantity
        if (doc.data().Quantity <= 0) {        
            alert('Promo Code expired.')
            return false
        }
        //check that discount is less than actual price
        if (doc.data().Price >= this.state.TotalPrice) {
            alert('Promo code cannot be used for this order.')
            return false
        }
        //if the person has already opted out of delivery, then a free delivery coupon cannot be applied
        if ((doc.data().Description == 'Free Delivery') && (this.state.PickupChecked )) {
            
            alert('This code cannot be applied for this order.')
            return false
        }

        //If there is no shipping fee then free shipping code cannot be applied
        if ((doc.data().Description == 'Free Shipping') && (this.state.ShippingPrice == 0)) {
            alert('This code cannot be applied for this order.')
            return false
        }

        //If there is no shipping fee + customzation then free shipping/customization code cannot be applied
        if ((doc.data().Description == 'Free Shipping and Customization') && ((this.state.ShippingPrice == 0) || (this.state.CustomizeChecked == false)) ){
            alert('This code cannot be applied for this order.')
            return false
        }
        
        return true
      } 

    CheckPromoCode () {
        //query the promocodes
        const query = firestore.collection('PromoCodes')
                        .where('Code', '==', this.state.PromoCode)
                        .get()
                        .then((querySnapshot) => {
                            if (querySnapshot.empty) {
                                alert('No matching promo code found.')
                            }
                          querySnapshot.forEach((doc) => {
                            //if a matching promocode is found, validate it, then apply it
                            if (this.ValidatePromoCode(doc)) {                              
                                this.setState({PromoCodeApplied: true, DiscountDescription: doc.data().Description, DiscountPrice: -(doc.data().Price), TotalPrice: this.state.TotalPrice-doc.data().Price})
                            }                           
                          })
                        })
                                

    }

    

    
    render(){
		// this.props.user && console.log(this.props.user.uid)										 

        // const hidden = this.state.checked ? 'hidden' : '';
		const DeliveryorPickup = this.state.PickupChecked ? 'Cash on Pickup' : 'Cash on Delivery'
        const DeliveryOptions = this.state.PickupChecked 
            ?
            <table className='center'>
                    <tr>
                        <td>
                            &nbsp;
                        </td>

                        <td>
                            <label>Pickup Location: 3 Carmody Road St. Augustine.</label>
                            <br/>
                            <label className='notice'>Please allow 5 business days for printing of any customized items.</label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Name: </label>
                        </td>
                        <td>
                            <input className="" type='text' required={this.state.PickupChecked} onChange={(e) => this.setState({DeliveryName: e.target.value})} value={this.state.DeliveryName}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Contact Number: </label>   
                        </td>
                        <td>
                            <input
                                className=""
                                type="text"
                                pattern="[0-9*]"
                                maxLength = "10"
                                required={!this.state.PickupChecked}
                                onChange={(e) => {
                                                    const n =  e.target.value.replace(/\D/,'')
                                                    this.setState({DeliveryTelNumber: n})
                                                }
                                            }
                                value={this.state.DeliveryTelNumber}/>    
                        </td>
                    </tr>

                    {this.props.user &&
                        (this.props.user.isAnonymous || this.props.user.email == 'soccerexpressionztt@gmail.com') &&
                            <tr>
                                <td>
                                    <label>Email: </label>
                                </td>
                                <td>
                                    <input className="" type='text' required={this.props.user.isAnonymous} onChange={(e) => this.setState({Email: e.target.value})} value={this.state.Email}/>
                                </td>
                            </tr>
                    }

                    {/* {this.props.user &&
                        this.props.user.isAnonymous ?

                        <tr>
                            <td>
                                <label>Email: </label>
                            </td>
                            <td>
                                <input className="" type='text' required={this.props.user.isAnonymous} onChange={(e) => this.setState({Email: e.target.value})} value={this.state.Email}/>
                            </td>
                        </tr>                    
                        :
                        this.props.user.Email == 'soccerexpressionztt@gmail.com' &&
                            <tr>
                                <td>
                                    <label>Email: </label>
                                </td>
                                <td>
                                    <input className="" type='text' required={this.props.user.isAnonymous} onChange={(e) => this.setState({Email: e.target.value})} value={this.state.Email}/>
                                </td>
                            </tr>
                    } */}

            </table>
            :
            <div >                
                <label>Courier Delivery Fee: 50TT$</label><br/>
                <label>Order <b>IN STOCK</b> items now and get it delivered between <u>{this.state.EstimatedDeliveryMin.toString()} and {this.state.EstimatedDeliveryMax.toString()}</u>.</label><br/>              
                <br/>
                
                <table className='center'>
                    <tr>
                        <td>
                            <label>Full Name: </label>
                        </td>
                        <td>
                            <input className="" type='text' required={!this.state.PickupChecked} onChange={(e) => this.setState({DeliveryName: e.target.value})} value={this.state.DeliveryName}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Address Line 1: </label>    
                        </td>
                        <td>
                            <input className="" type='text' required={!this.state.PickupChecked} onChange={(e) => this.setState({DeliveryAddress1: e.target.value})} value={this.state.DeliveryAddress1}/>   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Address Line 2: </label>    
                        </td>
                        <td>
                            <input className="" type='text' onChange={(e) => this.setState({DeliveryAddress2: e.target.value})} value={this.state.DeliveryAddress2}/>   
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>City: </label>   
                        </td>
                        <td>
                            <input className="" type='text' required={!this.state.PickupChecked} onChange={(e) => this.setState({DeliveryCity: e.target.value})} value={this.state.DeliveryCity}/>    
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Contact Number: </label>   
                        </td>
                        <td>
                            <input
                                className=""
                                type="text"
                                pattern="[0-9*]"
                                maxLength = "7"
                                required={!this.state.PickupChecked}
                                onChange={(e) => {
                                                    const n =  e.target.value.replace(/\D/,'')
                                                    this.setState({DeliveryTelNumber: n})
                                                }
                                            }
                                value={this.state.DeliveryTelNumber}/>    
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label>Confirm Contact Number: </label>   
                        </td>
                        <td>
                            <input
                                className=""
                                type="text"
                                pattern="[0-9*]"
                                maxLength = "7"
                                required={!this.state.PickupChecked}
                                onChange={(e) => {
                                                    const n =  e.target.value.replace(/\D/,'')
                                                    this.setState({DeliveryTelNumberConfirm: n})
                                                }
                                            }
                                value={this.state.DeliveryTelNumberConfirm}/>    
                        </td>
                    </tr>

                    {/* {console.log(this.props.user)} */}
                    {this.props.user &&
                        (this.props.user.isAnonymous || this.props.user.email == 'soccerexpressionztt@gmail.com') &&
                            
                            <tr>
                                <td>
                                    <label>Email: </label>
                                </td>
                                <td>
                                    <input className="" type='text' required={this.props.user.isAnonymous} onChange={(e) => this.setState({Email: e.target.value})} value={this.state.Email}/>
                                </td>
                            </tr>
                    }

                    {/* {this.props.user &&
                        this.props.user.isAnonymous ?

                        <tr>
                            <td>
                                <label>Email: </label>
                            </td>
                            <td>
                                <input className="" type='text' required={this.props.user.isAnonymous} onChange={(e) => this.setState({Email: e.target.value})} value={this.state.Email}/>
                            </td>
                        </tr>                    
                        :
                        this.props.user.Email == 'soccerexpressionztt@gmail.com' &&
                            <tr>
                                <td>
                                    <label>Email: </label>
                                </td>
                                <td>
                                    <input className="" type='text' required={this.props.user.isAnonymous} onChange={(e) => this.setState({Email: e.target.value})} value={this.state.Email}/>
                                </td>
                            </tr>
                    } */}

                </table>                
            </div>;        

        return (
            <> 
            <div>
                { (this.state.Items.length === 0) ? (
                    <a className="w3-button w3-blue" href='/'>Your cart is empty. <RiEmotionSadLine/>  Let's fill it up.</a>
                ) : (
                    <>
                    {/* //TEMPORARILY DISABLE PICKUP OPTION */}
                    {/* <div>
                        <label>Do not deliver. I will pick up: </label>
                        <input 
                        type="checkbox"
                        checked={ this.state.PickupChecked } 
                        onChange={ this.handleDeliveryCheckBoxChange } />
                    </div> */}
                    {/* //TEMPORARILY DISABLE PICKUP OPTION */}

                    {DeliveryOptions}   

                    
                    
                    <br/>
                    <table className='center'>
                        {this.state.Items.map((Jersey) => (
                                
                                    <tr>
                                        <td>
                                        <CartItem Item={Jersey} onQuantityChange={this.handleQuantityChange} onPlayerNoteChange={this.handlePlayerNoteChange}/>
                                        </td>
                                        <td>                                            
                                            <BrowserRouter>
                                                <Switch>
                                                    <Link to='/cart' onClick={async () => this.handleRemoveFromCart(Jersey)}><RiDeleteBin5Line size={32}/></Link> 
                                                </Switch>  
                                            </BrowserRouter>                          
                                        </td>
                                    </tr>
                                     
                                
                            ))  } 
                            <tr>
                            {this.state.PromoCodeApplied ? 
                                <>
                                    <td>
                                        <div className='CartItem'>
                                            <table className='CartItem'>
                                                <tr>
                                                    <td><img className='JerseyImgThumb' src={'https://firebasestorage.googleapis.com/v0/b/soccerexpressionz-test.appspot.com/o/promocode.png?alt=media&token=c3893d21-e420-4c4b-bd73-08baf3f30b1a'}/> </td>
                                                    <td><label>{this.state.DiscountDescription}</label> </td> 
                                                    <td>Discount</td>
                                                    <td>Quantity: {this.state.DiscountQuantity}</td>                                                                                                       
                                                    <td><label>TT$ {this.state.DiscountPrice.toFixed(2)}</label> </td>
                                                </tr>
                                            </table>
                                        </div> 
                                    </td> 
                                    <td><RiDeleteBin5Line size={32}/></td>                                       
                                </>
                                :                            
                                <>                                            
                                    <label>Discount Code:&nbsp;</label>
                                    <input className="" type='text' onChange={(e) => this.setState({PromoCode: e.target.value.toUpperCase()})} value={this.state.PromoCode}/>
                                    &nbsp;
                                    <button onClick={() => this.CheckPromoCode()}>Apply</button>
                                </>
                                }
                            </tr>
                    </table>
                    </>             
                
                )}               
            </div>
            <br/>

            <div>
                {/* //FOR TEMPORARY USE ONLY
                /////////////////////////////
                 REMOVE BEFORE BUILD// */}
				{this.props.user !== null &&
                    this.props.user.email == 'soccerexpressionztt@gmail.com' &&
				
				<div>
				
				<button
                    className="w3-button w3-blue"
					disabled={this.state.processing}
					onClick={() => {
						if (!this.state.processing) {
                            this.ValidateDelivery().then( 
							//ensure that the enterred data is valid before taking customer to payment link
                            (result) => {
                                if (result) {                                       
                                    // console.log('COD?', this.state.COD)
                                    if (this.state.PickupChecked) {
                                        alert('Orders not picked up within 24 hours will be automatically cancelled!')
                                    }                            
                                    this.setState({COD: true, processing: true})
                                    this.handleTestOrderApprove()
                                }
							}
                            )
						}
						// this.setState({COD: false})
						}}
					>
						{this.state.processing ? 'Processing Order...' : 'TEST'}
				 <br/> ${this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice}
				</button>
				&nbsp;&nbsp;
					
				<button
                    className="w3-button w3-blue"
					// disabled={this.state.processing}
					onClick={() => {
                        console.log('processing')
						if (!this.state.processing) {
							this.ValidateDelivery().then( 
                                //ensure that the enterred data is valid before taking customer to payment link
                                (result) => {
                                    if (result) {                                        
                                        console.log('COD?', this.state.COD)
                                        if (this.state.PickupChecked) {
                                            alert('Orders not picked up within 24 hours will be automatically cancelled!')
                                        }                            
                                        this.setState({COD: true, processing: true})
                                        this.handleOrderApprove()
                                    }
                                }
                            )
							}
						}
						// this.setState({COD: false})
						}
					>
						{/* {this.state.processing ?
						'Processing Order...'
						:
						<> */}
						BANK TRANSFER <br/> ${this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice}
						{/* </> */}
						{/* } */}
				</button>    
				<br/>
                <br/>
				</div>
                }
            

                {/* //FOR TEMPORARY USE ONLY
                /////////////////////////////
                 REMOVE BEFORE BUILD// */}

                {this.state.checkout ? (
                <>
                
                <label className='notice'>Remember to change currency to USD to avoid PayPal's extra fees!</label><br/>
                
                <PayPal value={this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice} OrderApproved={this.handleOrderApprove}/>
                
                </>
                
                ) : (
                    (this.state.Items.length > 0) && 
                        <>

                        <button
                        className="w3-button w3-blue"
                        onClick={() => this.handleReceivePayment()}                          
                        >
                            <FaCcMastercard/> <FaCcVisa/> <br/> Local Debit Card (Linx)/Credit Card  <br/> TT${this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice}
                        </button>  

                        &nbsp;&nbsp;

                        <button
                        className="w3-button w3-blue"
                        onClick={() => {this.ValidateDelivery().then((result) => {this.setState({checkout: result})})}}
                        >
                        <FaPaypal/> <br/> Paypal<br/> US${((this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice)/6.75).toFixed(2)}
                        </button>

                        <br/><br/>          

                        </>
                        
                )} 
                
                
                     
                {(this.state.Items.length > 0) && (
                   //disable checkout via COD for customized/out of stock jerseys
                    ((this.state.ShippingPrice != 0) || (this.state.CustomizeChecked)) ? (                        
                        <>
                          

                        {/* TEMPORARILY REMOVE CASH ON PICKUP FOR XMAS SEASON */}
                        {/* {!this.state.PickupChecked && ( */}
                
                            
                            <button className='disabled'//Disabled Cash on Delivery Button
                            onClick={() => {                           
                                        alert('Customized/Out of Stock orders must be paid for in advance. Please contact us on Instagram/soccerexpressionztt@gmail.com to pay for this order with a bank transfer if you do not own a LINX/Debit/Credit Card')
                                    }                            
                                }
                            >
                            {DeliveryorPickup} <br/> TT${this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice}
                            </button>

                        {/* )} */}
                        </>                            
                    )
                    :
                    (
                        <>
                        &nbsp;&nbsp;
                        {/* TEMPORARILY REMOVE CASH ON PICKUP FOR XMAS SEASON */}
                        {/* {!this.state.PickupChecked && ( */}
                
                          
                        <button //Enabled Cash on Delivery Button 
                            className="w3-button w3-blue" 
                            disabled={this.state.processing}
                            onClick={() => {
                                if (!this.state.processing) {
                                    this.ValidateDelivery().then( 
                                        //ensure that the enterred data is valid before taking customer to payment link
                                        (result) => {
                                            if (result) {                             
                                                // console.log('COD?', this.state.COD)
                                                if (this.state.PickupChecked) {
                                                    alert('Orders not picked up within 24 hours will be automatically cancelled!')
                                                }                            
                                                this.setState({COD: true, processing: true})
                                                this.handleOrderApprove()
                                            }
                                        }
                                    )
                                    }
                                }
                                // this.setState({COD: false})
                                }
                            >
                                {this.state.processing ?
                                'Processing Order...'
                                :
                                <>
                                {DeliveryorPickup} <br/> ${this.state.TotalPrice+this.state.ShippingPrice+this.state.DeliveryPrice}
                                </>
                                }
                        </button>

                        {/* )}                         */}
                        </>
                    )
                )}
            </div>

            <br/>

            <div>
                {this.state.ShippingPrice === 70 && 
                <React.Fragment>
                    <label className='notice'>Your cart contains items that are not in stock and will have to be ordered.</label><br/>
                    <label className='notice'>70TT$ shipping fee applied.</label><br/>
                    <label className='notice'>SoccerExpressionz cannot guarantee shipping times due to congested global shipping lanes and customs delays.</label><br/>
                    <label className='notice'>Please allow up to 3-5 weeks for delivery.'</label>
                </React.Fragment>
                }
            </div>
            <div>
                {(this.state.Items.length !== 0) &&
                <>
                <div>
                <BrowserRouter>
                    <Switch>
                        <Link className="w3-button w3-blue" to='/cart' onClick={this.EmptyCart}>Empty Cart<br/><MdRemoveShoppingCart size={40}/></Link> 
                    </Switch>  
                </BrowserRouter>
                </div>
                <br/><br/>
                <div>
                    <a className="w3-button w3-blue" href='/'>Shop some more...</a>
                    <br/>
                    <br/>
                </div>
                </>}
            </div>

            <div>
              Powered by:
              <img src="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/visa.png?alt=media&token=58072175-6a2a-4ba6-8f73-c21e9e417aa6"/>
              <img src="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/mastercard.png?alt=media&token=bde740a0-3530-421d-b6c5-d07dc11101ec"/>
              <img src="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/linx.png?alt=media&token=8d54046b-9d8e-4545-8f94-c12785399ab8"/>
              <img src="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/paypal.png?alt=media&token=3082ec48-8d45-406e-a7b3-77dbf99f2a6e"/>
              <img src="https://firebasestorage.googleapis.com/v0/b/richersimulations-b57cb.appspot.com/o/republicbanktt.png?alt=media&token=6fbc1366-bd0d-4a0b-ad2c-c46f46d0c93e"/>
            </div>

            </>
        )
        
    }
}

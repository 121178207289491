import React, {useState} from 'react'
import {storage, firestore} from '../config/Config.js'
// import { setUserProperties } from '@firebase/analytics';

//components
import { SelectLeague } from './SelectLeague';
import { SelectTeam } from './SelectTeam';
import { SelectCut } from './SelectCut';
import { SelectSleeve } from './SelectSleeve';
import { SelectSize } from './SelectSize';
import { SelectVariant } from './SelectVariant';
import { SelectPlayer } from './SelectPlayer';
import { JerseyImage } from './JerseyImage.js';
import { MdCheckBox } from 'react-icons/md';

export class RemoveEmptyStock extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            League: '2023-2024',
            Error: '',
            LeagueOptions: [{label: 'Loading Leagues', value: 'Loading Leagues'}],
            
    }
        this.handleLeagueSelect = this.handleLeagueSelect.bind(this);        
        this.handleAddJersey = this.handleAddJersey.bind(this);
        this.GetLeagues = this.GetLeagues.bind(this);    
        
        
    }

    async GetLeagues() {
        const data = [];
        const Leagues = await firestore.collection('Leagues').get()
        for (var doc of Leagues.docs){
            data.push({value: doc.data().League, label: doc.data().League}); 
        }
        // console.log(data)
        this.setState({LeagueOptions: data});
    }

    handleLeagueSelect(league) {  
    //THIS CODE IS REPEATED ELSEWHERE
    //we need to query the first team in the new league collection in order to change the Team select box
    //otherwise the team from the old league will still be set and the lookup for the rest of the select boxes will fail.
    firestore.collection("Leagues").doc(league).collection("Teams")
    .orderBy("Team", "desc")
    .limit(1)
    .get().then(querySnapshot => {
    if (!querySnapshot.empty) {
        //We know there is one doc in the querySnapshot                  
            const queryDocumentSnapshot = querySnapshot.docs[0];                  
            this.setState({Team: queryDocumentSnapshot.data().Team})
    }
    });        

    // let StateCopy = this.state;
    // StateCopy.League = league;
    // this.setState({StateCopy});
    this.setState({League: league});
    }


    async handleAddJersey(e) {
    e.preventDefault();

    var deleted = 0;

    //delete sizes with StockQuantity 0, delete any empty snapshots
    firestore.collection("Leagues").doc(this.state.League).collection("Teams").get().then(queryTeamsSnapshot => {
    if (!queryTeamsSnapshot.empty) {                         
        queryTeamsSnapshot.forEach((Team) => {
            // console.log(Team.data().Team)
            firestore.collection("Leagues").doc(this.state.League).collection("Teams").doc(Team.data().Team).collection("Cuts").get().then(queryCutsSnapshot => {
                if (!queryCutsSnapshot.empty) {
                    queryCutsSnapshot.forEach((Cut) => {
                        // console.log(Cut.data().Cut)
                        firestore.collection("Leagues").doc(this.state.League).collection("Teams").doc(Team.data().Team).collection("Cuts").doc(Cut.data().Cut).collection("Sleeves").get().then(querySleevesSnapshot => {
                            if (!querySleevesSnapshot.empty) {
                                querySleevesSnapshot.forEach((Sleeve) => {
                                    // console.log(Sleeve.data().Sleeve)
                                    firestore.collection("Leagues").doc(this.state.League).collection("Teams").doc(Team.data().Team).collection("Cuts").doc(Cut.data().Cut).collection("Sleeves").doc(Sleeve.data().Sleeve).collection("Variants").get().then(queryVariantsSnapshot => {
                                        if (!queryVariantsSnapshot.empty) {
                                            queryVariantsSnapshot.forEach((Variant) => {
                                                // console.log(Variant.data().Variant)
                                                firestore.collection("Leagues").doc(this.state.League).collection("Teams").doc(Team.data().Team).collection("Cuts").doc(Cut.data().Cut).collection("Sleeves").doc(Sleeve.data().Sleeve).collection("Variants").doc(Variant.data().Variant).collection("Sizes").get().then(querySizesSnapshot => {
                                                    if (!querySizesSnapshot.empty) {
                                                        querySizesSnapshot.forEach((Size) => {
                                                            // console.log(Size.data().Size)
                                                            if (Size.data().StockQuantity == 0) {
                                                                // deleted+=1
                                                                Size.ref.delete().then(deleted+=1);
                                                            }                                        
                                                        })
                                                        console.log('Deleted '+deleted+' records.')
                                                    }
                                                    else
                                                    {
                                                        firestore.collection("Leagues").doc(this.state.League).collection("Teams").doc(Team.data().Team).collection("Cuts").doc(Cut.data().Cut).collection("Sleeves").doc(Sleeve.data().Sleeve).collection("Variants").doc(Variant.data().Variant).delete()   
                                                    }

                                                })
                                            })
                                        }
                                        else
                                        {
                                            firestore.collection("Leagues").doc(this.state.League).collection("Teams").doc(Team.data().Team).collection("Cuts").doc(Cut.data().Cut).collection("Sleeves").doc(Sleeve.data().Sleeve).delete()   
                                        }
                                    })
                                })
                            }
                            else
                            {
                                firestore.collection("Leagues").doc(this.state.League).collection("Teams").doc(Team.data().Team).collection("Cuts").doc(Cut.data().Cut).delete()   
                            }
                        })
                    })
                }
                else
                {
                    firestore.collection("Leagues").doc(this.state.League).collection("Teams").doc(Team.data().Team).delete()   
                }
            })
        })
    }
    });     
    }

    


    render() {
        return (        
            <div className='container'>
                {/* {!this.props.user || (this.props.user && this.props.user.Email != 'soccerexpressionztt@gmail.com') ?
                <>Please Sign In</>                
                :
                <> */}
                <h2>Add Jersey</h2>
                <form onSubmit={this.handleAddJersey}> {/**/}            
                    <SelectLeague onLeagueSelect={this.handleLeagueSelect} LeagueOptions={this.state.LeagueOptions} GetLeagues={this.GetLeagues}/>
                



                    <button>Remove Out of Stock Jerseys</button>
                    <br/>
                </form>
                {Error && <span>{Error}</span>}
                This may need to be run 5 times to remove empty teams. Or at least once for each level.
                {/* </>
                } */}
            </div>
        )
    }
}
